.flatlist{
    margin-bottom: 60px;
}
.main-container{
    margin-left: 100px;
    width: calc(100vw - 100px)
}
.button-top-left{
    align-self: flex-start;
    margin: 10px;
}
.button-top-right{
    align-self: flex-end;
    margin: 10px;
}
.modal-large{
    width: 60%;
    margin-left: 20%;
}
.modal-large .modal-dialog{
    max-width: 100%;
}