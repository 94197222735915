.container {
  position: relative;
  display: flex;
  height: 100vh;
  left: 460px;
  width: calc(100%);
  width: calc(100%);
  padding-left: 15%;
  padding-top: 20px;
  flex-direction: column;
}
#container-filters {
  width: 100%;
  input {
    min-width: 350px;
  }
}
.form {
  padding-top: 17px;
  padding-left: 10px;
}
.top-bar {
  display: flex;
  flex-direction: row;
}
.top-text {
  padding-top: 20px;
  padding-left: 20px;
}
.top-text2 {
  padding-top: 20px;
  padding-left: 10px;
}
.title {
  padding-top: 20px;
  width: 38%;
}

.text-search {
  padding-bottom: 12px;
  width: 38%;
}

.top-bar2 {
  display: flex;
  flex-direction: row;
}
.top-text3 {
  padding-left: 5px;
  font-size: small;
}
.top-text4 {
  padding-left: 20px;
}
.form2 {
  padding-left: 35px;
  width: 52%;
}
.button {
  padding-top: 15px;
  padding-left: 15px;
}
.row {
  display: flex;
  flex-direction: row;
}
.button2 {
  width: 80%;
  text-align: right;
}
.info {
  display: flex;
  flex-direction: row;
}
.top-bar-collor {
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 100%;
}
.button-info {
  padding-left: 5px;
}
.top-bar3 {
  display: flex;
  flex-direction: row;
}
.curva {
  display: flex;
  flex-direction: row;
}
.icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.tr {
  background-color: gray;
  border-color: gray;
}
thead {
  position: sticky;
  top: 0px;
}
th {
  justify-content: center;
}

.button-cadastra-usuario {
  margin-right: 50%;
}

.form-div-get-user {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin: auto;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  height: 50vh;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.custom-form-control {
  width: 100%;
  margin-left: 8px;
  margin-top:10px;
}